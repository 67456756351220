import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import FormDateTimeInput from "../../../../shared/react/FormDateTimeInput.jsx";
import FormStringInput from "../../../../shared/react/FormStringInput.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";

function RequestEcgDataForm({
  // Props
  study,
  enrollmentToUse,
  handleClose,
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  const {handleSubmit, control} = useForm();
  const {isDirty} = useFormState({control});
  const {fullName} = useJwt();

  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      try {
        const preDuration = Number(data.preDuration);
        const postDuration = Number(data.postDuration);

        const action = {
          deviceId: enrollmentToUse.deviceId,
          enrollmentId: enrollmentToUse.enrollmentId,
          facilityId: study.facilityId,
          createdBy: fullName,
          comment: data.comment,
          data: JSON.stringify({
            requestedTime: data.datetime.toMillis(),
            startTime: data.datetime.minus({minutes: preDuration}).toMillis(),
            preDuration,
            postDuration,
            duration: preDuration + postDuration,
            comment: data.comment,
          }),
        };

        await axios({
          method: "post",
          url: "/actions/requestEcgData",
          data: action,
        });

        handleClose();
      } catch (err) {
        if (err?.response?.data?.title) {
          setError(err.response.data.title);
        } else {
          setError(err.message);
        }
      }

      setSubmitting(false);
    },
    [enrollmentToUse, study, handleClose, fullName]
  );

  const defaultDatetime = React.useMemo(() => {
    if (study.studyEndDate) {
      return DateTime.fromISO(study.studyEndDate);
    }
    return DateTime.now();
  }, [study.studyEndDate]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <TabPanel value="requestEcgData" data-cy="request-ecg-data">
      <Alert message={error} setMessage={setError} level="error" />
      {study.allEnrollments?.length > 1 && (
        <Alert
          message="Data from a previous device is not requestable"
          level="warning"
          otherProps={{mb: 2}}
        />
      )}

      <IconWithText
        icon={<Smartphone color="tertiary" />}
        text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
      />

      <Typography variant="body2" sx={{mt: 2}}>
        Enter the data and time of interest and the number of minutes of ECG data to retrieve pre and post.
      </Typography>

      <FormProvider {...{control}}>
        <Grid container spacing={3} sx={{mt: 2}}>
          <Grid size={{xs: 12, md: 4}}>
            <FormDateTimeInput
              control={control}
              name="datetime"
              label="Date and Time"
              defaultValue={defaultDatetime}
              data-cy="datetime-picker"
              size="small"
              variant="outlined"
              timeZone={study.timeZone}
              timeSteps={{minutes: 1}}
              views={["year", "day", "hours", "minutes"]}
              disableFuture
              minDateTime={DateTime.fromISO(study.studyStartDate)}
              maxDateTime={DateTime.fromISO(study.studyEndDate)}
              rules={{
                required: "Date and Time is required",
                validate: (value) => {
                  if (value.invalid) {
                    return "Please enter a valid date";
                  }
                  if (value > DateTime.local().setZone(study.timeZone)) {
                    return "Date cannot be in the future";
                  }
                  if (study.studyStartDate && value < DateTime.fromISO(study.studyStartDate)) {
                    return "Date cannot be before the start of the study";
                  }
                  if (study.studyEndDate && value > DateTime.fromISO(study.studyEndDate)) {
                    return "Date cannot be after the end of the study";
                  }
                  return true;
                },
              }}
            />
          </Grid>
          <Grid size={{xs: 12, md: 4}}>
            <FormStringInput
              control={control}
              name="preDuration"
              label="Pre-Duration (1-15 minutes)"
              type="number"
              defaultValue="1"
              data-cy="pre-duration-input"
              htmlInputProps={{min: 1, max: 15}}
              size="small"
              otherProps={{variant: "outlined"}}
              rules={{
                required: "Pre-Duration is required",
                min: {value: 1, message: "Pre-Duration must be greater than or equal to 1"},
                max: {value: 15, message: "Pre-Duration must be less than or equal to 15"},
                validate: {
                  integer: (value) => Number.isInteger(Number(value)) || "Pre-Duration must be an integer",
                },
              }}
            />
          </Grid>
          <Grid size={{xs: 12, md: 4}}>
            <FormStringInput
              control={control}
              name="postDuration"
              label="Post-Duration (1-15 minutes)"
              type="number"
              defaultValue="1"
              data-cy="post-duration-input"
              htmlInputProps={{min: 1, max: 15}}
              size="small"
              otherProps={{variant: "outlined"}}
              rules={{
                required: "Post-Duration is required",
                min: {value: 1, message: "Post-Duration must be greater than or equal to 1"},
                max: {value: 15, message: "Post-Duration must be less than or equal to 15"},
                validate: {
                  integer: (value) => Number.isInteger(Number(value)) || "Post-Duration must be an integer",
                },
              }}
            />
          </Grid>

          <Grid size={12}>
            <CommentField />
          </Grid>
        </Grid>
      </FormProvider>
      <Box sx={{width: "100%", display: "inline-flex", justifyContent: "flex-end", mt: 2}}>
        <Box sx={{mr: 2}}>
          <CancelButton
            color="secondary"
            isDirty={isDirty}
            onClick={handleClose}
            data-cy="cancel-action-button"
          >
            Cancel
          </CancelButton>
        </Box>
        <Box>
          <LoadingButton
            data-cy="submit-action-button"
            disabled={submitting || !isDirty}
            variant="contained"
            color="secondary"
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            Request ECG Data
          </LoadingButton>
        </Box>
      </Box>
    </TabPanel>
  );
}

RequestEcgDataForm.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

export default RequestEcgDataForm;
